(function () {
    angular.module('aerosApp')
        .directive('aflLinkmapOtdrEventSection', aflLinkmapOtdrEventSection);

    function aflLinkmapOtdrEventSection() {
        return {
            restrict: 'E',
            scope: {
                fiberGroup: '=',
                fiberId: '=',
                eventCount: '=',
                imageMap: '=',
                iconMap: '=',
                event: '=',
                unit: '='
            },
            controller: aflLinkmapOtdrEventSectionController,
            templateUrl: '/static/javascript/directives/afl-linkmap/afl-linkmap-otdr-event-section.html'
        }
    }

    aflLinkmapOtdrEventSectionController.$inject = ['$scope', 'LinkmapService'];

    function aflLinkmapOtdrEventSectionController($scope, LinkmapService) {
        angular.extend($scope, {
            getLocationPrecision: LinkmapService.getLocationPrecision
        });
    }
})();
